import "./bootstrap";

import Alpine from "alpinejs";
import focus from "@alpinejs/focus";

require("@fortawesome/fontawesome-free/js/all.js"); // for JavaScript

window.Alpine = Alpine;

Alpine.plugin(focus);

Alpine.start();
window.Alpine = Alpine;

// Start: Sidebar
const sidebarToggle = document.querySelector(".sidebar-toggle");
const sidebarOverlay = document.querySelector(".sidebar-overlay");
const sidebarMenu = document.querySelector(".sidebar-menu");
const main = document.querySelector(".main");

if (sidebarToggle) {
    if (window.innerWidth < 768) {
        main.classList.toggle("active");
        sidebarOverlay.classList.toggle("hidden");
        sidebarMenu.classList.toggle("-translate-x-full");
    }
    sidebarToggle.addEventListener("click", function (e) {
        main.classList.toggle("active");
        sidebarMenu.classList.toggle("-translate-x-full");
        sidebarOverlay.classList.toggle("hidden");
        /* e.preventDefault();
        main.classList.toggle("active");
        sidebarMenu.classList.remove("-translate-x-full");
        sidebarOverlay.classList.toggle("hidden");
        if (window.innerWidth > 768) {
            sidebarOverlay.classList.add("hidden");
            sidebarMenu.classList.add("-translate-x-full");
            sidebarMenu.classList.toggle("md:-translate-x-0");
        }*/
    });
    sidebarOverlay.addEventListener("click", function (e) {
        e.preventDefault();
        main.classList.add("active");
        sidebarOverlay.classList.add("hidden");
        sidebarMenu.classList.add("-translate-x-full");
    });

    window.addEventListener("resize", function () {
        if (window.innerWidth < 768) {
            main.classList.toggle("active");
            sidebarOverlay.classList.add("hidden");
            sidebarMenu.classList.add("-translate-x-full");
        } else {
            main.classList.remove("active");
            sidebarOverlay.classList.toggle("hidden");
            sidebarMenu.classList.remove("-translate-x-full");
        }
        /*
        if (window.innerWidth > 768 && main.classList.contains("active")) {
            main.classList.remove("active");
            sidebarOverlay.classList.add("hidden");
            sidebarMenu.classList.add("-translate-x-0");
            sidebarMenu.classList.remove("-translate-x-full");
        } else {
            sidebarMenu.classList.remove("-translate-x-0");
            sidebarMenu.classList.add("-translate-x-full");
            if (
                sidebarMenu.classList.contains("-translate-x-full") &&
                window.innerWidth > 768
            ) {
                sidebarMenu.classList.add("-translate-x-0");
                sidebarMenu.classList.remove("-translate-x-full");
            }
        }*/
    });
    document
        .querySelectorAll(".sidebar-dropdown-toggle")
        .forEach(function (item) {
            item.addEventListener("click", function (e) {
                e.preventDefault();
                const parent = item.closest(".group");
                if (parent.classList.contains("selected")) {
                    parent.classList.remove("selected");
                } else {
                    document
                        .querySelectorAll(".sidebar-dropdown-toggle")
                        .forEach(function (i) {
                            i.closest(".group").classList.remove("selected");
                        });
                    parent.classList.add("selected");
                }
            });
        });
}
// End: Sidebar

// star: Popper
const popperInstance = {};
document.querySelectorAll(".dropdown").forEach(function (item, index) {
    const popperId = "popper-" + index;
    const toggle = item.querySelector(".dropdown-toggle");
    const menu = item.querySelector(".dropdown-menu");
    menu.dataset.popperId = popperId;
    popperInstance[popperId] = Popper.createPopper(toggle, menu, {
        modifiers: [
            {
                name: "offset",
                options: {
                    offset: [60, 8],
                },
            },
            {
                name: "preventOverflow",
                options: {
                    padding: 24,
                },
            },
        ],
        placement: "bottom-end",
    });
});
document.addEventListener("click", function (e) {
    const toggle = e.target.closest(".dropdown-toggle");
    const menu = e.target.closest(".dropdown-menu");
    if (toggle) {
        const menuEl = toggle
            .closest(".dropdown")
            .querySelector(".dropdown-menu");
        const popperId = menuEl.dataset.popperId;
        if (menuEl.classList.contains("hidden")) {
            hideDropDown();
            menuEl.classList.remove("hidden");
            showPopper(popperId);
        } else {
            menuEl.classList.add("hidden");
            hidePopper(popperId);
        }
    } else if (!menu) {
        hideDropDown();
    }
});
function hideDropDown() {
    document.querySelectorAll(".dropdown-menu").forEach(function (item) {
        item.classList.add("hidden");
    });
}
function showPopper(popperId) {
    popperInstance[popperId].setOptions(function (options) {
        return {
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: "eventListeners", enabled: true },
            ],
        };
    });
    popperInstance[popperId].update();
}
function hidePopper(popperId) {
    popperInstance[popperId].setOptions(function (options) {
        return {
            ...options,
            modifiers: [
                ...options.modifiers,
                { name: "eventListeners", enabled: false },
            ],
        };
    });
}
// end: Popper

// start: tab-notification
document.querySelectorAll("[data-tab]").forEach(function (item) {
    item.addEventListener("click", function (e) {
        e.preventDefault();
        const tab = item.dataset.tab;
        const page = item.dataset.tabPage;
        const target = document.querySelector(
            '[data-tab-for="' + tab + '"][data-page="' + page + '"]'
        );

        document
            .querySelectorAll('[data-tab="' + tab + '"]')
            .forEach(function (i) {
                i.classList.remove("active");
            });
        document
            .querySelectorAll('[data-tab-for="' + tab + '"]')
            .forEach(function (i) {
                i.classList.add("hidden");
            });
        item.classList.add("active");
        target.classList.remove("hidden");
    });
});
// end: tab-notification

// start: Chart
if (document.getElementById("order-chart") !== null) {
    new Chart(document.getElementById("order-chart"), {
        type: "line",
        data: {
            labels: generateNDays(7),
            datasets: [
                {
                    label: "Active",
                    data: generateRandomData(7),
                    borderWidth: 1,
                    fill: true,
                    pointBackgroundColor: "rgb(59, 130, 246)",
                    borderColor: "rgb(59,130,246)",
                    backgroundColor: "rgb(59 130 246 / .05)",
                    tension: 0.2,
                },
                {
                    label: "Completed",
                    data: generateRandomData(7),
                    borderWidth: 1,
                    fill: true,
                    pointBackgroundColor: "rgb(16, 185, 129)",
                    borderColor: "rgb(16,185,129)",
                    backgroundColor: "rgb(16 185 129 / .05)",
                    tension: 0.2,
                },
                {
                    label: "Cancelled",
                    data: generateRandomData(7),
                    borderWidth: 1,
                    fill: true,
                    pointBackgroundColor: "rgb(244, 63, 94)",
                    borderColor: "rgb(244,63,94)",
                    backgroundColor: "rgb(244 63 94 / .05)",
                    tension: 0.2,
                },
            ],
        },
        options: {
            scales: {
                y: {
                    beginAtZero: true,
                },
            },
        },
    });
}
function generateNDays(n) {
    const data = [];
    for (let i = 0; i < n; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        data.push(
            date.toLocaleString("en-US", {
                month: "short",
                day: "numeric",
            })
        );
    }
    return data;
}

function generateRandomData(n) {
    const data = [];
    for (let i = 0; i < n; i++) {
        data.push(Math.round(Math.random() * 10));
    }
    return data;
}
// end: Chart
